import DetailsFields from "../../components/Timesheets/Standard/Fields/DetailsFields";
import { Grid, Typography, Button, IconButton } from "@mui/material";
import TopBar from "../../components/Layout/Standard/TopBar";
import { useContext, useRef, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorBox from "../../components/Common/ErrorBox";
import dayjs from "dayjs";
import { combineDateTime } from "../../utils/utils";
import DeleteEntryDialog from "../../components/Common/DeleteEntryDialog";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { axiosInstance } from "../../utils/utils";
import { useTranslation } from "react-i18next";

const EditTime = () => {
    const [userContext, setUserContext] = useContext(UserContext);
    const topRef = useRef(null);
    const navigate = useNavigate();
    const [disableButton, setDisableButton] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const loaded = useRef(false);
    const [, setModified] = useState(false);
    const [details, setDetails] = useState({
        _id: "",
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        project: "",
        area: "",
        description: "",
        breakMinutes: ""
    });
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    const handleSubmit = event => {
        event.preventDefault();
        setDisableButton(true);

        const startDate = combineDateTime(details.startDate, details.startTime);
        const endDate = combineDateTime(details.endDate, details.endTime);

        let body = { ...details };
        delete body["startTime"];
        delete body["endTime"];
        body = { ...body, startDate: startDate, endDate: endDate };

        const url = process.env.REACT_APP_API_URL + "/timesheets/" + searchParams.get("id");
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.post(url, body, config)
            .then(res => {
                navigate("/main", { replace: true, state: { snackbarMessage: t("editTime.submittedMessage") } });
            })
            .catch(err => {
                setErrorMessage(t("editTime.errorMessage"));
                setShowErrorMessage(true);
                setDisableButton(false);
            });
    };

    useEffect(() => {
        const fetchEntry = () => {
            const url = process.env.REACT_APP_API_URL + '/timesheets?ids=["' + searchParams.get("id") + '"]';
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    const startTime = dayjs(combineDateTime(new Date(), res.data[0].startDate));
                    const endTime = dayjs(combineDateTime(new Date(), res.data[0].endDate));

                    setDetails({
                        _id: res.data[0]._id,
                        startDate: dayjs(res.data[0].startDate),
                        startTime: dayjs(startTime),
                        endDate: dayjs(res.data[0].endDate),
                        endTime: dayjs(endTime),
                        project: res.data[0].project,
                        area: res.data[0].area,
                        description: res.data[0].description,
                        breakMinutes: res.data[0].breakMinutes
                    });
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchEntry();
    }, [userContext, setUserContext, searchParams]);

    const handleDelete = () => {
        const url = process.env.REACT_APP_API_URL + "/timesheets/" + searchParams.get("id");
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.delete(url, config)
            .then(res => {
                navigate("/main", { replace: true, state: { snackbarMessage: t("editTime.deletedMessage") } });
            })
            .catch(err => {
                setErrorMessage(t("editTime.errorMessage"));
                setShowErrorMessage(true);
            });
    };

    useEffect(() => {
        const fieldsNotEmpty = () => {
            const startDate = dayjs(combineDateTime(details.startDate, details.startTime));
            const endDate = dayjs(combineDateTime(details.endDate, details.endTime));
            const requiredDetails = { ...details };
            const validDateRange = startDate.isBefore(endDate);

            return Object.values(requiredDetails).every(value => value !== "" && value !== undefined && value !== null) && validDateRange;
        };

        if (!isLoading) {
            if (loaded.current) {
                setModified(true);
            } else {
                loaded.current = true;
            }
        }

        setDisableButton(!fieldsNotEmpty());
    }, [details, isLoading]);

    useEffect(() => {
        if (!topRef.current) return;
        topRef.current.scrollIntoView();
    }, [topRef]);

    useEffect(() => setIsLoading(details._id === ""), [details]);

    return (!isLoading &&
        <Grid container direction="column" sx={{ maxWidth: "1000px" }} ref={topRef}>
            <Grid container item sx={{ justifyContent: "center" }}>
                <TopBar title={t("editTime.heading")} enableBack modified={false} />
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px" }}>
                <Typography color="black">{t("editTime.subheading")}</Typography>
            </Grid>
            <Grid container item sx={{ justifyContent: "center" }}>
                <form onSubmit={handleSubmit} style={{ maxWidth: "500px" }}>
                    <DetailsFields details={details} setDetails={setDetails} loaded={loaded} />
                    <Grid item container sx={{ mt: 4, mb: 1.5 }}>
                        <Typography variant="body1" sx={{ fontWeight: "bold", color: "#495464" }}>{t("editTime.remove")}</Typography>
                    </Grid>
                    <Grid container item sx={{ justifyContent: "space-between", alignItems: "center" }}>
                        <Grid item>
                            <Typography>{t("editTime.removeText")}</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => setOpenDelete(true)}><DeleteOutlineIcon sx={{ fontSize: 30, color: "#495464" }} /></IconButton>
                        </Grid>
                    </Grid>
                    <br />
                    <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                    <Button disabled={disableButton} type="submit" sx={{ width: "100%", marginTop: "30px", height: "45px", marginBottom: "35px" }}>{t("editTime.change")}</Button>
                </form>
            </Grid>
            {openDelete && <DeleteEntryDialog open={openDelete} setOpen={setOpenDelete} handleDelete={handleDelete} title={t("remove.title")} text={t("remove.text")} deleteText={t("remove.remove")} cancelText={t("remove.cancel")} />}
        </Grid >
    );
};

export default EditTime;