import './App.css';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useContext, useEffect, useCallback } from 'react';
import { UserContext } from './context/UserContext';
import axios from 'axios';
import theme from './themes/theme';
import Layout from './components/Layout/Admin/Layout';
import Dashboard from './pages/Admin/Dashboard';
import Timesheets from './pages/Admin/Timesheets';
import CreateReport from './pages/Admin/CreateReport';
import Projects from './pages/Admin/Projects';
import MissingTimesheets from './pages/Admin/MissingTimesheets';
import Users from './pages/Admin/Users';
import Settings from './pages/Admin/Settings';
import Login from './pages/Admin/Login';
import LayoutStd from './components/Layout/Standard/Layout';
import Main from './pages/Standard/Main';
import AddTime from './pages/Standard/AddTime';
import EditTime from './pages/Standard/EditTime';
import MissingTimesheetsSpv from './pages/Standard/MissingTimesheets';
import i18n from './i18n';

function App() {
  const [userContext, setUserContext] = useContext(UserContext);
  const location = useLocation();

  const verifyUser = useCallback(() => {
    const url = process.env.REACT_APP_API_URL + '/users/refreshtoken';
    const body = { reloaded: true };
    const config = { withCredentials: true };

    axios.post(url, body, config)
      .then(res => {
        setUserContext(userContext => ({ ...userContext, ...res.data }));
      })
      .catch(err => {
        // Do nothing
      });
  }, [setUserContext]);

  useEffect(() => {
    verifyUser();
  }, [verifyUser]);

  useEffect(() => {
    if (userContext.lastReloaded
      && userContext.lastUpdated
      && new Date(userContext.lastReloaded) < new Date(userContext.lastUpdated)
      && new Date(userContext.lastUpdated) < new Date()
    ) {
      window.location.reload();
    }
  }, [location, userContext]);

  useEffect(() => {
    if (userContext.language) {
      i18n.changeLanguage(userContext.language);
    }
  }, [userContext]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Routes>
        {!userContext.token ?
          <Route>
            <Route index element={<Login />} />
            <Route path='*' element={null} />
          </Route>
          :
          userContext.userType === "admin" ?
            <Route element={<Layout />}>
              <Route index element={<Navigate to='dashboard' />} />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='timesheets' element={<Timesheets />} />
              <Route path='createreport' element={<CreateReport />} />
              <Route path='projects' element={<Projects />} />
              <Route path='missingtimesheets' element={<MissingTimesheets />} />
              <Route path='users' element={<Users />} />
              <Route path='settings' element={<Settings />} />
            </Route>
            :
            <Route element={<LayoutStd />}>
              <Route index element={<Navigate to='main' />} />
              <Route path='main' element={<Main />} />
              <Route path='missingtimesheets' element={<MissingTimesheetsSpv />} />
              <Route path='addtime' element={<AddTime />} />
              <Route path='edittime' element={<EditTime />} />
            </Route>
        }
      </Routes>
    </ThemeProvider>
  );
}

export default App;